export default {
  eventDetails: "Details de l'événement",
  create: 'Créer',
  update: "Mettre à jour",
  delete: 'Supprimer',
  close: 'Fermer',
  cancel: 'Annuler',
  andEvents: "et ses événements ?",
  participantDeletion: 'Supprimer un participant',
  startDate: "Date de début",
  endDate: "Date de fin",
  title:"titre",
  dateFormat: "fr-fr",
  itemIsRequired: "L'item est requis",
  contactInformation: "Contact",
  name: "Nom",
  email: "Email",
  phone: "Téléphone",
  contactText: "Pour tout demande, assistance ou suggestions contactez-nous. Nous apprécions vos retours!",
  createNewCalendar: "Créer un nouveau calendrier",
  participants: "Participants",
  load: "Charger",
  myCalendars: "Mes calendriers",
  clickOnYourName: "Click sur ton prénom",
  copytToClipboard: "Copié dans le presse papier",
  calendarID: "Coller le lien du calendrier ici",
  calendarURL: "URL du calendrier",
  shareCalendar: "Partager",
  newParticipant: "Nouveau Participant",
  add: "Ajouter",
  newParticipantName: "Nom du nouveau participant",
  newCalendarName:"Nom du nouveau calendrier",
  invalidID: "l'ID est invalide",
  invalidCalendarDeleteMessage: "Ce calendrier n'est plus valide.",
  emptyCalendarsText: "Vous ne semblez pas avoir de calendrier précédent. Vous devriez en créer ou en charger un !",
  importCalendarText: "Demande à tes amis le lien du calendrier que tu souhaiterais rejoindre. Ensuite copie et colle le lien ci-dessous.",
  loadCalendar: "Importer un calendrier",
  atLeastOneParticipant: "Il faut ajouter au moins un participant.",
  invalidURL: "L'URL est invalide",
  urlMustBeFromApp: "L'URL doit venir de l'application",
  calendarIDNotEmpty: "L'id du calendrier ne peut pas être vide.",
  shareCalendarText: "Rejoins moi sur mon calendrier collaboratif !",
  wrongUserSelected: " doit être selectionné.e pour mettre cet évènement à jour mais c'est actuellement "
}
